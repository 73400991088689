<template>
         <section class="page_title  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <div class="content_box">
                           <h6>About the Project </h6>
                           <h1>More About the Project</h1>
                           <ul class="bread_crumb text-center">
                              <li class="bread_crumb-item"><a href="#">Home</a></li>
                              <li class="bread_crumb-item"><a href="#">About</a></li>
                              <li class="bread_crumb-item active"> About the Project</li>
                           </ul>
                        </div>
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>


             <section class="symptoms_content_outer pdt_100  pdb_100">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-6 col-md-12 d-flex">
                        <!---------col---------->
                        <div class="symptoms_content">
                           <div class="heading tp_one">
                              <h1>Project Description</h1>
                           </div>
                           <p style="text-align:justify">The COVID-19 pandemic is an unprecedented global health crisis that has affected all countries and territories in the world, causing more than three million deaths. In response to the COVID-19 pandemic toll in Africa, several countries mobilized significant financial, human, logistical and technical support to strengthen pre-existing public health response capacities of their health systems. Several countries instituted non-pharmaceutical interventions with varying intensities and coverage such as closure of education and religious institutions, banning of public transport and overnight curfew. These interventions have the potential to significantly disrupt national and household economies and could negatively impact health service delivery and uptake. These effects could vary across countries depending on the nature and extent of the non-pharmaceutical interventions as well as the fidelity with which they are implemented.This project aimed to assess and curate the (i) response to the COVID-19 pandemic in Africa and the
outcomes in terms of COVID-19 control and (ii) continuity of essential non-COVID-19 health services
within the COVID-19 period, to inform the COVID-19 response and recovery as well as health system
resilience to future disease outbreaks in Africa. The study is conducted in five countries: The Democratic
Republic of Congo (DRC), Ghana, Nigeria, Senegal, and Uganda.</p>
                           
                        </div>
                        <!---------col-end--------->
                     </div>
                     <div class="col-lg-6 col-md-12 d-flex">
                        <!---------col---------->
                        
                        <div class="image_box">
                           <img src="/assets/image/resources/projectdescription.jpg" style=" border-top-left-radius: 40px; border-bottom-right-radius: 40px;"  class="img-fluid" alt="img" />
                        </div>

                        <!---------col-end--------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
            </section>

<section class="howitworks type_one pdt_100">
    <div class="container">
        <div class="row">
            <!---------row----------->
            <div class="col-lg-6 col-md-12">
            <!----col------>
            <div class="image_box">
                <img src="/assets/image/resources/africa_covid_vaccination.jpg" class="img-fluid" alt="img" />
            </div>
            <!----col-end------>
            </div>
            <div class="col-lg-6 col-md-12 d-flex">
            <!----col------>
            <div class="content_box">
                <div class="heading tp_one">
                    <!-- <h6>It’s so simple</h6> -->
                    <h1>Project Approaches</h1>
                </div>
                <ul class="steps">
                    <li><span>1</span>Review of literature including published articles, reports, briefs and blogs.</li>
                    <li><span>2</span>Analysis of secondary data from the Health Management Information Systems.</li>
                    <li><span>3</span>Key informant interviews with stakeholders at national and subnational levels and communities.</li>
                    
                </ul>
            </div>
            <!----col-end------>
            </div>
            <!----------row-end---------->
        </div>
    </div>
</section>

<section class="spreading   pdt_50  pdb_60">
    <div class="container">
        <div class="row">
            <!---------row----------->
            <div class="col-lg-6 col-md-12">
            <!---------col----------->
            <div class="heading tp_one">
                <h1>Study outcomes </h1>
                <h6>The project has six overarching outcomes described below:</h6>
                
                <p></p>
            </div>
            <!---------col-end---------->
            </div>
            <!----------row-end---------->
        </div>


           <div class="row">
                          
                <div class="col-lg-12">
                    <div class="text_box" >
                    <table style="background-image:url('@/assets/image/resources/covid19.png')">
                    <tbody>
                    <tr>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"><span style="color:black">Outcome#1:</span></b>
                    </p>
                    </td>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"> Documentation of the surveillance, diagnosis and treatment strategies employed by countries during the response to the COVID-19 pandemic.</b>
                    </p>
                    </td> 
                    </tr>


                    <tr>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"><span style="color:black">Outcome#2:</span></b>
                    </p>
                    </td>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"> Case exemplars of approaches and interventions undertaken by African governments to ensure continuity of non COVID-19 routine care.</b>
                    </p>
                    </td> 
                    </tr>


                    <tr>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"><span style="color:black">Outcome#3:</span></b>
                    </p>
                    </td>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px">Case exemplars of feasibility and effectiveness of approaches and interventions taken by African governments which have been successful or unsuccessful (for the COVID-19 response).</b>
                    </p>
                    </td> 
                    </tr>



                    <tr>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"><span style="color:black">Outcome#4:</span></b>
                    </p>
                    </td>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px">Documentation of packages of non-pharmaceutical interventions and health systems interventions that countries can consider in response to current or future waves of COVID-19 and future epidemics of other diseases.</b>
                    </p>
                    </td> 
                    </tr>




                    <tr>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"><span style="color:black">Outcome#5:</span></b>
                    </p>
                    </td>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px">Case exemplars of approaches and interventions undertaken by African governments to mitigate the negative socioeconomic and gender vulnerabilities due to COVID-19.</b>
                    </p>
                    </td> 
                    </tr>



                    <tr>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-bottom: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px"><span style="color:black">Outcome#6:</span></b>
                    </p>
                    </td>
                    <td style="border-left: 2px solid #53627E;border-right: 2px solid #53627E;border-bottom: 2px solid #53627E;border-top: 2px solid #53627E; padding:14px">
                     <p  class="mb-2">
                       <b style="font-size:18px">Strengthen a network/community of practice of universities focused on assessing the impact of public health emergencies of international concern in general and COVID-19 in particular for policy makers, based on existing networks housed by MakSPH.</b>
                    </p>
                    </td> 
                    </tr>


                    </tbody>
                    </table>

                    </div>
                </div>
            </div>


  
    </div>
</section>

<section class="howitworks type_one pdt_100 pdb_60">
    <div class="container">
        <div class="row">
            <!---------row----------->
            <div class="col-lg-7 col-md-12" >
            <!----col------>
            <div class="image_box" >
                <img  src="@/assets/image/resources/projectoutputs.jpg" style=" border-top-right-radius: 40px; border-bottom-left-radius: 40px;" class="img-fluid" alt="img" />
            </div>
            <!----col-end------>
            </div>
            <div class="col-lg-5 col-md-12 d-flex">
            <!----col------>
            <div class="content_box">
                <div class="heading tp_one">
                    <!-- <h6>It’s so simple</h6> -->
                    <h1>Project Outputs</h1>
                </div>
                <ul class="steps">
                    <li><span>1</span>Presentation on testing and surveillance approaches employed by countries during the response to the COVID-19 pandemic.</li>
                    <li><span>2</span>Report on testing and surveillance approaches employed by countries during the response to the COVID-19 pandemic.</li>
                    <li><span>3</span>Presentation on approaches and interventions undertaken by countries to ensure continuity of non-COVID-19 routine care testing.</li>
                    <li><span>4</span>Report on testing and surveillance approaches employed by countries during the response to the COVID-19 pandemic.</li>
                    
                </ul>
            </div>
            <!----col-end------>
            </div>
            <!----------row-end---------->
        </div>
    </div>
</section>


<section class="partners type_one  pdt_100  pdb_70">
   <div class="container">
      <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
               <!---------col----------->
               <div class="heading text-center tp_one">
                  <!-- <h6>Recent from Updates</h6> -->
                  <h1>Project Funders</h1>
                 
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
   </div>
      <div class="container">
      
         <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
               <!---------col----------->
               <div class="owl-carousel four_items">
                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/gates.png" class="img-fluid" style="width:250px !important; height:150px !important; object-position:fit; object-size:cover" alt="partners" />
                  </div>
                  <div class="partners_logo type_one">
                     <img src="@/assets/image/clients/gatesvetures.jpg" class="img-fluid" style="width:250px !important; height:150px !important; object-position:fit; object-size:cover" alt="partners" />
                  </div>

                  

               
               </div>
               <!---------col-end---------->
            </div>
            <!----------row-end---------->
         </div>
      </div>
</section>
</template>
<script>
export default {
    
    mounted(){
        var scripts = [
        "assets/js/all.js",
        "assets/js/custom.js",
        ];
        scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);
        })

    },
}
</script>